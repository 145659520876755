import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { v4 as uuidv4 } from 'uuid';

const loadExternalScript = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const fraud_api = 'http://www.social-sms.com/iq-dcb/dcbprotect.php';
      const css_selector = '#subscribe';
      const ts = new Date().getTime();
      const ti = uuidv4() + '-' + ts;
      const te = encodeURIComponent(css_selector);
      const servicename = 'gameland';
      const merchantname = 'strongbits';
      const type = 'he';
      const url = `${fraud_api}?action=script&ti=${ti}&ts=${ts}&te=${te}&servicename=${servicename}&merchantname=${merchantname}&type=${type}`;
      console.log(url);
      if (window.location.pathname === '/lp/he') {
        const response = await fetch(url, { method: 'GET' });
        const result = await response.json();
        if (result && result.s) {
          const scriptTag = document.createElement('script');
          scriptTag.type = "text/javascript";
          scriptTag.innerHTML = result.s;
          document.head.appendChild(scriptTag);
          var event = new Event("DCBProtectRun");
          document.dispatchEvent(event);
        }
      }
      resolve({ ti, ts });
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

const getCampaign = async (campaignId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch('https://iraqgtw.kid-security.io/ill?campaignId=' + campaignId,
        {
          method: 'GET',
          headers: {
            "Content-Type": "application/json"
          }
        });
      if (response.ok) {
        const data = await response.json();
        resolve(data);
      }
    }
    catch (error) {
      console.error(error);
      reject(error);
    }
  });
};


const init = async () => {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    var campaignId = urlParams.get('campaignId');
    const res = await getCampaign(campaignId);

    const { ti, ts } = await loadExternalScript();

    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
      <React.StrictMode>
        <App ti={ti} ts={ts} camp={res.code} params={urlParams} />
      </React.StrictMode>
    );
  } catch (error) {
    console.error(error);
  }
};

init();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
